import { INDEX_INSTRUMENTS_ONLY_WEEKLY_EXPIRY } from 'v2/common/constants/index';

export const isIndexInstrumentPresentInInstrumentGroup = (instrumentGroups, instrumentIndex = 0) => {
  const { indexInstruments } = window;

  return _.some(instrumentGroups, (instrumentGroup) => {
    return _.includes(indexInstruments, _.get(instrumentGroup, instrumentIndex, ''));
  });
};

export const isIndexInstrumentWithWeeklyExpiryOnly = (instrumentGroups, instrumentIndex = 0) => {
  const isIndexInstrument = isIndexInstrumentPresentInInstrumentGroup(instrumentGroups, instrumentIndex);
  return isIndexInstrument && _.some(instrumentGroups, (instrumentGroup) => {
    return _.includes(INDEX_INSTRUMENTS_ONLY_WEEKLY_EXPIRY, _.get(instrumentGroup, instrumentIndex, ''));
  });
};
