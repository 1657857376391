export const BUILDER_QUANT_CONFIG_VERSION = 2.7;
export const SPECIAL_CHARACTER_REGEX = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>?~]/;

export const ORDER_TYPES = {
  paper: { value: 'paper', displayName: 'Paper Testing' },
  oneClick: { value: 'oneclick', displayName: 'Oneclick Trading' },
  automatic: { value: 'automatic', displayName: 'Auto Trading' }
};

export const BFO_INSTRUMENTS = ['SENSEX', 'BANKEX'];

export const RUN_TYPES = { live: 'live', historic: 'historic' };
export const RUN_GRANULARITY = { second: 'second', minute: 'minute' };
export const OPTION_TYPES = { CE: 'CE', PE: 'PE' };
export const TRANSACTION_TYPES = { buy: 'buy', sell: 'sell' };
export const SL_TRAIL_TYPES = { Linear: 'Linear', BookAndLinear: 'BookAndLinear' };

export const SEGMENT_CONFIG = { future: 'future', equity: 'equity', option: 'option' };

export const PRODUCT_TYPES = {
  mis: { label: 'MIS', value: 'mis' },
  normal: { label: 'Normal', value: 'normal' },
};

export const STOPS_VALUE_TYPES = { Percent: 'Percent', Amount: 'Amount', Points: 'Points' };
export const POSITION_VALUE_TYPES = { Lot: 'lot', Amount: 'amount' };

export const INTENT_TYPES = {
  CloseLeg: 'CloseLeg',
  ExecuteLeg: 'ExecuteLeg',
  ExitTransaction: 'ExitTransaction',
  UpdateStopLoss: 'UpdateStopLoss',
  ReEntry: 'ReEntry',
  OpenBasket: 'OpenBasket',
  OpenLeg: 'OpenLeg',
};

export const INTENT_CATEGORIES = {
  Open: 'Open',
  Other: 'Other',
};

export const getIntentCategory = (intentType) => {
  if (intentType === INTENT_TYPES.CloseLeg || intentType === INTENT_TYPES.UpdateStopLoss) {
    return INTENT_CATEGORIES.Other;
  }
  return INTENT_CATEGORIES.Open;
};

export const QUICK_ADJUSTMENT_TRIGGERS = {
  ExitedInLoss: { value: 'ExitedInLoss', displayName: 'Exited in Loss', shortLabel: 'LossExit' },
  ExitedInProfit: { value: 'ExitedInProfit', displayName: 'Exited in Profit', shortLabel: 'ProfitExit' },
};
export const QUICK_ADJUSTMENT_ACTIONS = {
  CloseLeg: { value: 'CloseLeg', displayName: 'Close', shortLabel: 'Close' },
  ExecuteLeg: { value: 'ExecuteLeg', displayName: 'Open', shortLabel: 'Open' },
  MoveToCost: { value: 'MoveToCost', displayName: 'Move To Cost', shortLabel: 'MTC' }
};

export const WAIT_AND_TRADE_TYPES = {
  WAIT_UNTIL_PREMIUM_TO_INCREASE_IN_PERCENTAGE: 'waitUntilPremiumToIncreaseInPercentage',
  WAIT_UNTIL_PREMIUM_TO_DECREASE_IN_PERCENTAGE: 'waitUntilPremiumToDecreaseInPercentage',
};

export const STRIKE_SELECTION_TYPES = {
  EquityBasedStrikeIndex: {
    label: 'Spot Based',
    value: 'EquityBasedStrikeIndex',
    key: 'strikeIndex',
    isPermissionToChoose: true,
    isShow: true,
  },
  FutureBasedStrikeIndex: {
    label: 'Future Based',
    value: 'FutureBasedStrikeIndex',
    key: 'strikeIndex',
    isPermissionToChoose: true,
    isShow: true,
  },
  StrikePrice: {
    label: 'Strike Price',
    value: 'StrikePrice',
    key: 'strikePrice',
    isPermissionToChoose: true,
    isShow: true,
  },
  PremiumBased: {
    label: 'Premium',
    value: 'PremiumBased',
    key: 'premium',
    isPermissionToChoose: true,
    isShow: true,
    comparsions: [
      { label: 'near', value: 'Nearest' },
      { label: 'greater', value: 'GreaterThan' },
      { label: 'lesser', value: 'LesserThan' }
    ]
  },
  IndicatorValueBasedStrikeIndex: {
    label: 'Indicator Based',
    value: 'IndicatorValueBasedStrikeIndex',
    key: 'strikeIndex',
    isPermissionToChoose: _.get(window, 'SA_FEATURE_FLAGS.shouldShowIndicatorBasedStrikeIndex', false)
      || window?.isAdmin || false,
    isShow: true,
  },
  DeltaBased: {
    label: 'Delta',
    value: 'DeltaBased',
    key: 'delta',
    isPermissionToChoose: _.get(window, 'SA_FEATURE_FLAGS.shouldShowDeltaBased', false)
      || window?.isAdmin || false,
    isShow: _.get(window, 'SA_FEATURE_FLAGS.shouldShowDeltaBased', false)
    || window?.isAdmin || false,
    comparsions: [
      { label: 'near', value: 'Nearest' },
      { label: 'greater', value: 'GreaterThan' },
      { label: 'lesser', value: 'LesserThan' }
    ]
  },
};

export const EXPIRY_MONTHLY_DETAILS = {
  '1-monthly': {
    config: { index: 1, cycle: 'monthly' },
    label: 'Current Month',
  },
  '2-monthly': {
    config: { index: 2, cycle: 'monthly' },
    label: 'Next Month',
  },
};

export const EXPIRY_WEEKLY_DETAILS = {
  '1-weekly': {
    config: { index: 1, cycle: 'weekly' },
    label: 'Current Week',
  },
  '2-weekly': {
    config: { index: 2, cycle: 'weekly' },
    label: 'Next Week',
  },
};

export const CHART_PATTERNS = {
  candle: {
    label: 'Candle',
    value: 'candle',
    isDisplay: true,
  },
  heikinashi: {
    label: 'Heikin Ashi',
    value: 'heikinashi',
    isDisplay: true,
  },
  openInterest: {
    label: 'Open Interest',
    value: 'openInterestCandle',
    isDisplay: true,
  },
  volume: {
    label: 'Volume',
    value: 'volumeCandle',
    isDisplay: true,
  },
};

export const QUANT_CONFIG_ORDER_TYPES = {
  market: { label: 'Market', value: 'market' },
  limit: { label: 'Limit', value: 'limit' },
  'sl-l': { label: 'SL Limit', value: 'sl-l' },
};

export const EXTERNAL_SIGNALS_VENDOR_TYPES = {
  TradingView: 'TradingView',
  ChartInk: 'ChartInk',
};

export const INDEX_INSTRUMENTS_ONLY_WEEKLY_EXPIRY = [
  'NFO:FINNIFTY',
  'NFO:MIDCPNIFTY',
  'BFO:SENSEX',
  'BFO:BANKEX'
];

export const MAX_CASES_IN_QUANT = 5;

export const INSTAOPTIONS_MODE = {
  live: 'live',
  historical: 'historical',
};
